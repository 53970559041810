import { Injectable, Injector } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AuthenticationService } from '@finder/shared/services/authentication/authentication.service';
import { STATUS_CODES } from '@finder/shared/constants/response-code.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private injector: Injector
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.includes('/finder/api')) {
            // No auth cookie needed
            return next.handle(req);
        }

        // Dont call /authz while doing /authz
        // infinite loop FTW
        if (req.url.includes('/authz')) {
            req = req.clone({
                withCredentials: true
            });

            return next.handle(req);
        }

        // All api calls should include the auth cookie
        const auth = this.injector.get(AuthenticationService);

        return auth.getAuthToken()
            .pipe(
                mergeMap(() => {
                    req = req.clone({
                        withCredentials: true
                    });

                    return next.handle(req).pipe(
                        tap(() => {}, err => {
                            if (err instanceof HttpErrorResponse
                                && (err.status === STATUS_CODES.UNAUTHORIZED || err.status === STATUS_CODES.FORBIDDEN)) {
                                // if for any reason a 401 or 403 happens
                                // remove the auth token to ask for a new one
                                auth.removeTokenCookie();
                            }
                        })
                    );
                })
            );
    }
}
