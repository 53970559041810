import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

import { SoloService } from '@finder/shared/services/solo-service/solo.service';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { ConfigService } from '@finder/core/config.service';
import { STATUS_CODES } from '@finder/shared/constants/response-code.constants';

@Injectable({
    providedIn: 'root'
})
export class MyIdGuard implements CanActivate {

    constructor(
        private soloService: SoloService,
        private windowRef: WindowRef,
        private config: ConfigService,
    ) { }

    canActivate() {
        // Check the env var to see if gating is disabled
        if (this.config.getValue('disableMyIDGating') === true) {
            return of(true);
        }

        // Call the API for gating check
        return this.soloService.validateToken()
            .pipe(
                map(token => token.valid),
                catchError(error => {
                    if (error && error.status === STATUS_CODES.UNAUTHORIZED) {
                        // Redirect to myid login
                        this.windowRef.nativeWindow.location.href =
                            this.soloService.getLoginRedirectUrl(this.windowRef.nativeWindow.location.href);
                    }

                    return of(false);
                })
            );
    }
}
