import { Component, ViewContainerRef, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SeoService } from '@wdpr/ra-angular-seo-metadata';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import moment from 'moment';

import { ConfigService } from '@finder/core/config.service';
import { COMMON_LOCALES_MAPPING, CONFIG_CONSTANTS, LOCALES, LOCALE_DEFAULT } from '@finder/shared/constants/app.constants';
import { FinderThemeService } from '@finder/shared/services/theme-service/finder-theme.service';
import { LoadingService } from '@finder/shared/services/loading-service/loading-service.service';
import { InterstitialService } from '@finder/shared/services/interstitial-service/interstitial-service.service';
import { LocaleService } from '@finder/shared/services/locale-service/locale-service.service';
import { FinderApiConnectorService } from '@finder/shared/services/finder-api-connector/finder-api-connector.service';
import { FinderAnalyticsService } from '@finder/shared/services/finder-analytics/finder-analytics.service';
import { LazyLoaderService } from '@finder/shared/services/lazy-loader/lazy-loader-service';
import { FinderPageKeyService } from '@finder/shared/services/finder-page-key/finder-page-key.service';
import { LoadingServiceErrorState } from '@finder/shared/services/loading-service/loading-service.interface';
import { DineToFinderErrorService } from '@finder/shared/services/dine-to-finder-error/dine-to-finder-error.service';
import { RouteStateService } from '@finder/shared/services/route-state-service/route-state-service';
import { LocaleHelperService } from '@finder/shared/services/locale-service/locale-helper.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild('rootComponents', { read: ViewContainerRef, static: true }) rootComponents: ViewContainerRef;
    @ViewChild('customHeaderTemplate', { read: ViewContainerRef, static: true }) customHeader: ViewContainerRef;
    @ViewChild('customFooterTemplate', { read: ViewContainerRef, static: true }) customFooter: ViewContainerRef;

    syndicatedLayoutConfigs: Record<string, any>;
    siteId: string;
    environment: string;
    apiBase: string;
    dataLoaded = false;
    dataError: LoadingServiceErrorState;
    languageBundle: any;
    useSyndicated = true;
    currentCustomHeader;
    currentCustomFooter;
    private destroy$ = new Subject<void>();

    constructor(
        private configService: ConfigService,
        private themeService: FinderThemeService,
        private loadingService: LoadingService,
        private localeService: LocaleService,
        private localeHelperService: LocaleHelperService,
        public viewContainerRef: ViewContainerRef,
        private elementRef: ElementRef,
        private seoService: SeoService,
        private finderApi: FinderApiConnectorService,
        private finderAnalyticsService: FinderAnalyticsService,
        private lazyLoader: LazyLoaderService,
        private pageKeyService: FinderPageKeyService, // Do not remove - need this to listen
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private routeState: RouteStateService,
        private dineToFinderError: DineToFinderErrorService,
        private interstitialService: InterstitialService
    ) {
        this.syndicatedLayoutConfigs = this.configService.getValue('syndicatedLayout');
        this.siteId = this.configService.getValue('siteId');
        this.environment = this.configService.getValue('environment');
        this.apiBase =
            `${this.configService.getValue(CONFIG_CONSTANTS.apiUrlKey)}/${this.configService.getValue(CONFIG_CONSTANTS.defaultVersionKey)}`;

        this.setupLocale();
        this.setupRouteParams();
        this.setupTheme();
        this.setupLoadingService();
        this.setupLanguageBundle();
        this.setupSeo();
        this.setupAnalytics();
        this.setupCssOverride();
        this.setUpThemesOverride();
    }

    ngOnInit() {
        if (this.environment === CONFIG_CONSTANTS.developmentEnv) {
            this.lazyLoader.lazyLoadCssResource('lazyload-style.css');
        }
    }

    ngOnDestroy() {
        // Ignore the if just for coverage, there's no need to test unsubscribe
        /* istanbul ignore next */
        this.destroy$.next();
        this.destroy$.complete();
    }

    setupLocale() {
        this.routeState.pathParam()
            .pipe(
                map((params: any) => params.locale),
                // avoid to set the same locale multiple times
                distinctUntilChanged(),
                takeUntil(this.destroy$)
            )
            .subscribe((localeParam) => {
                /**
                 * When WorldServer is ready we can remove this method and the Interceptor
                 */
                let locale = localeParam || LOCALE_DEFAULT;

                // GDPR - We need to double check the languageSelect cookie to make sure this aligns with
                // the current locale. GDPR has unique eu/uk domains so no need for a locale within the URL
                const cookieLocale = this.localeService.getLocale();
                if (cookieLocale && cookieLocale !== locale) {
                    locale = cookieLocale;
                }

                moment.locale(locale);
                this.localeHelperService.setSiteId(this.siteId);
                this.localeHelperService.setUriLocale(locale);
                // We import the required locale from common folder
                this.importLocale();
            });
    }

    setupRouteParams() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                switchMap(() => this.activatedRoute.firstChild.params),
                takeUntil(this.destroy$)
            )
            .subscribe((params) => this.routeState.updatePathParamState(params));
    }

    /* istanbul ignore next */
    getElementReference() {
        // Added to make testing reachable
        return this.elementRef.nativeElement;
    }

    private setupTheme() {
        // Initialize default theme.
        this.themeService.setDefaultTheme();
    }

    private setupLoadingService() {
        this.loadingService.getLoadingState()
            .pipe(takeUntil(this.destroy$))
            .subscribe(loaded => {
                this.dataLoaded = loaded;
            });

        this.loadingService.getErrorState()
            .pipe(takeUntil(this.destroy$))
            .subscribe({next: (error) => this.dataError = error});
    }

    private setupLanguageBundle() {
        this.finderApi.getLanguageBundle()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.languageBundle = data;
                this.dineToFinderError.initialize(data.dineToFinderError);

                if (data.interstitialModal) {
                    this.interstitialService.setInterstitialData(data.interstitialModal);
                }
            });
    }

    private setupSeo() {
        this.seoService.setServerUrl(this.apiBase);
        this.seoService.init();
    }

    private setupAnalytics() {
        this.finderAnalyticsService.initAnalytics();
    }

    private setupCssOverride() {
        const body = this.getElementReference().closest('body');
        // Add the normalized locale and the site ID as CSS classes to the body
        // to allow targeting by brand and locale
        const locale = this.localeService.getNormalizedLocale();
        body.classList.add(this.siteId, locale);
    }

    private importLocale() {
        const locale = this.localeService.getNormalizedLocale();
        this.lazyLoader.lazyLoadLocale(COMMON_LOCALES_MAPPING[locale], locale);
    }

    private setUpThemesOverride() {
        const themesOverridesUrl =  this.finderApi.getCssOverridesUrl(this.siteId);
        this.lazyLoader.lazyLoadCssResource(themesOverridesUrl, false);
    }
}
